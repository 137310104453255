import React, { Component } from 'react'
import TextInput from '../TextInput'

class MapForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      coordinates: { latitude: null, long: null },
      location: '',
      validCoordinates: false,
      isEmpty: false
    }
  }

  componentDidMount () {
    const country = ['ng', 'ke']
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {
        types: ['geocode'],
        componentRestrictions: { country },
        strictBounds: false,
        origin: window.location.origin + '/api'
      }
    )

    autocomplete.addListener('place_changed', this.handlePlaceSelect)
    this.autocomplete = autocomplete
  }

  handlePlaceSelect = () => {
    const place = this.autocomplete.getPlace()

    if (!place.geometry) {
      return
    }

    const location = place.name
    this.setState({ location })
    this.showMap(place.formatted_address)
  }

  showMap = (location) => {
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ address: location }, (results, status) => {
      // If we get coordinates set state
      if (status === 'OK') {
        this.setState({
          coordinates: {
            lat: results[0].geometry.location.lat(),
            long: results[0].geometry.location.lng()
          },
          validCoordinates: true,
          isEmpty: false
        })
      } else {
        const coordinates = { latitude: null, long: null }
        this.setState({ validCoordinates: false, coordinates })
      }
      const city = {
        value: this.state.location,
        hasError: !this.state.validCoordinates
      }
      this.props.updateForm({ city })
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.country !== this.props.country) {
      const country = this.props.country ? [this.props.country] : ['ng', 'ke']
      this.autocomplete.setComponentRestrictions({ country })
    }

    if (prevState.location !== this.state.location) {
      if (this.state.location === '') {
        this.setState({ isEmpty: true, validCoordinates: false })
        const city = {
          value: this.state.location,
          hasError: !this.state.validCoordinates
        }
        this.props.updateForm({ city })
      }
    }

    if (
      prevState.coordinates.lat !== this.state.coordinates.lat &&
      prevState.coordinates.long !== this.state.coordinates.long &&
      this.state.validCoordinates
    ) {
      const map = new window.google.maps.Map(
        document.getElementById('map'),
        {
          zoom: 12,
          center: { lat: this.state.coordinates.lat, lng: this.state.coordinates.long }
        }
      )
      // eslint-disable-next-line no-new
      new window.google.maps.Marker({
        position: {
          lat: this.state.coordinates.lat,
          lng: this.state.coordinates.long
        },
        map: map
      })
      const lat = {
        value: this.state.coordinates.lat,
        hasError: false
      }
      const long = {
        value: this.state.coordinates.long,
        hasError: false
      }
      this.props.updateForm({lat, long})
    }
  }

  handleLocationChange = (e) => {
    this.setState({ location: e.target.value })
  }

  render () {
    const {label, sublabel, required, errorMessage, error} = this.props
    const {validCoordinates, isEmpty, location} = this.state
    return (
      <div>
        <TextInput
          id='autocomplete'
          type='text'
          value={location}
          onChange={this.handleLocationChange}
          label={label}
          sublabel={sublabel}
          placeholder='Start typing ....'
          required={required}
        />
        {validCoordinates && <div id='map' style={{ width: '350px', height: '250px' }} />}
        {(isEmpty || error) && React.createElement('span', { className: 'fb-input__error-text' }, errorMessage)}
      </div>
    )
  }
}

export default MapForm
