import React, { createRef } from 'react'
import { navigate } from 'gatsby'

import SignupForm from '../../SignupForm'
import Button from '../../Button'
import Box from '../../Box'
import Checkbox from '../../Checkbox'
import Text from '../../Text'
import { ExclamationTriangle } from '../../../icons'

import {COUNTRIES_GEO_ID} from '../../../utilities/constants'

class SignUp extends React.Component {
  inputs = [
    {
      label: 'Name of pharmacy:',
      name: 'nameOfPharmacy',
      required: true,
      errorMessage: 'This information is required'
    }, {
      label: 'Pharmacy owner’s name:',
      name: 'pharmacyOwnersName',
      required: true,
      errorMessage: 'This information is required'
    }, {
      label: 'Email:',
      name: 'email',
      required: true,
      isEmail: true,
      errorMessage: 'Please enter a valid email address'
    }, {
      label: 'Confirm email:',
      name: 'emailConfirmation',
      required: true,
      errorMessage: 'Email fields must match'
    }, {
      label: 'Owner\'s phone number:',
      name: 'phoneNumber',
      required: true,
      errorMessage: 'Please enter a valid phone number'
    }, {
      label: 'Retailer type:',
      name: 'pharmacyType',
      required: true,
      type: 'radio',
      values: [{
        value: 'Pharmacy',
        label: 'Pharmacy'
      }, {
        value: 'PPMV',
        label: 'PPMV'
      }, {
        value: 'Clinics and Hospitals',
        label: 'Hospital or clinic'
      }],
      errorMessage: 'This information is required'
    }, {
      label: 'Country:',
      name: 'country',
      required: true,
      type: 'radio',
      values: [{
        value: 'Nigeria',
        label: 'Nigeria',
        gmapsCode: 'ng'
      }, {
        value: 'Kenya',
        label: 'Kenya',
        gmapsCode: 'ke'
      }],
      errorMessage: 'This information is required'
    }, {
      label: 'State / Region:',
      name: 'state',
      required: true,
      type: 'select',
      values: [],
      errorMessage: 'This information is required'
    }, {
      label: 'City/Town:',
      name: 'city',
      required: true,
      children: ['lat', 'long'],
      hasChildren: true,
      type: 'map',
      errorMessage: 'Please enter a valid city/town',
      sublabel: 'Enter your city or town e.g. "Jabi", "Ikeja", "Parklands", "Langata" ....'
    }, {
      label: 'Address:',
      name: 'address',
      required: true,
      errorMessage: 'This information is required'
    }, {
      label: 'Referrer:',
      name: 'referrer',
      required: false,
      sublabel: 'The name of the pharmacy that referred you to Shelf Life (if applicable)'
    }
  ]

  formRef = createRef()

  state = {
    form: {},
    error: null,
    privacyPolicy: false,
    submitting: false,
    valid: true
  }

  triggerPrivacyPolicy = () => {
    const {privacyPolicy} = this.state
    this.setState({privacyPolicy: !privacyPolicy})
  }

  errorHandler = (isGenericError, error) => {
    this.setState({
      error: isGenericError ? 500 : error,
      submitting: false
    })
  }

  showConfirmation = () => {
    const fields = this.formRef.current.getFields()

    navigate(
      '/confirmation/',
      {
        state: { email: fields.email }
      }
    )
  }

  submit = async () => {
    this.setState({ submitting: true })

    const {privacyPolicy, submitting} = this.state
    const restApiUrl = getAgaveUrl(window.location.href)
    const valid = this.formRef.current.validate()

    if (!valid) {
      this.setState({ valid: false, submitting: false })
    }

    if (valid && privacyPolicy && !submitting) {
      this.setState({submitting: true, valid: true})
      const fields = this.formRef.current.getFields()
      delete fields.emailConfirmation

      fields.country = COUNTRIES_GEO_ID[fields.country]
      const startInputs = window.localStorage.getItem('startInputs')

      try {
        const response = await window.fetch(`${restApiUrl}signup`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            ...fields,
            startInputs
          })
        })

        if (response.status === 204 && response.ok) {
          window.localStorage.removeItem('startInputs')
          return this.showConfirmation()
        }

        const json = await response.json()

        if (json.error || !response.ok) {
          const isGenericError = (response.status >= 500 || (!json.error && !response.ok))
          return this.errorHandler(isGenericError, json.error)
        }
      } catch (e) {
        console.log(e)
        return this.errorHandler(true)
      } finally {
        this.setState({ submitting: false })
      }
    }
  }

  render () {
    const { submitting, error, valid } = this.state

    return (
      <Box className='signup-box' tight>
        <Text size='xlarge' weight='bold'>
          Let's get started
        </Text>
        <Box.Body className='signup-box__body'>
          <SignupForm ref={this.formRef} inputs={this.inputs} >
            <div className='privacy-policy'>
              <Checkbox onValueChange={this.triggerPrivacyPolicy} />
              <span className='privacy-policy__text'>
                I consent to the data entered being used in accordance with the
                Privacy Policy described at <a target='_blank' href='https://shelflife.co/legal/'>https://shelflife.co/legal/</a>
              </span>
            </div>
            {error && <div className='error-block'>
              <span className='error-block__text'>
                {error === 500 ? <span>
                  Sorry, we are having a problem with our system. Please contact one of our teams here <a href='https://shelflife.co/contact/'>https://shelflife.co/contact/</a>
                </span> : error}
              </span>
            </div> }
          </SignupForm>
        </Box.Body>

        <Box.Footer isColumn>
          <Button
            disabled={submitting}
            onClick={this.submit}
          >
            Get Started
          </Button>
          {!valid && (
            <Text className='signup-box__error' color='error'>
              <ExclamationTriangle />
              There is an error with one or more of your entries, please kindly check the form again.
            </Text>
          )}
        </Box.Footer>

      </Box>
    )
  }
}

function getAgaveUrl (href) {
  if (process.env.GATSBY_REST_API_URL) return process.env.GATSBY_REST_API_URL

  if (href.startsWith('https://shelflife.co/')) return 'https://shelflife.field.supply/agave/v1/'

  if (href.startsWith('https://staging.shelflife.co/')) return 'https://staging.shelflife.field.supply/agave/v1/'

  if (href.startsWith('https://dev.shelflife.co/')) return 'https://dev.shelflife.field.supply/agave/v1/'

  return 'https://dev.shelflife.field.supply/agave/v1/'
}

export default SignUp
